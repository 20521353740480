*,
::after,
::before {
  box-sizing: border-box;
}
/* fonts */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600&family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Petit+Formal+Script&display=swap');

html {
  font-size: 100%;
} /*16px*/



:root {
  /* colors */
  --primary-100: #33885e;
  --primary-200: #bdb329;
  --primary-300: #5d8f58;
  --primary-400: #577e4b;
  --favicon_green: #89af77;
  --primary-600: #5a8f5d;
  --primary-700: #5e7267;
  --primary-800: #1a996f;
  --primary-900: #1a3623;

  /* grey */
  --grey-50: #f8fafc;
  --grey-100: #f1f5f9;
  --grey-200: #e2e8f0;
  --brown-300: #694b23;
  --grey-400: #94b89c;
  --grey-500: #648b6e;
  --grey-600: #476951;
  --grey-700: #335537;
  --grey-800: #5f9255;
  --grey-900: #58753c;
  /* rest of the colors */
  --black: #222;
  --white: #fff;
  --red-light: #f8d7da;
  --red-dark: #842029;
  --green-light: #d1e7dd;
  --green-dark: #506d44;

  /* fonts  */
  --headingFont: 'Dancing Script';
  --bodyFont: 'Nunito', sans-serif;
  --moonaFont: 'Lora';
  --moonaFont2: 'Petit Formal Script';
  --smallText: 0.7em;
  /* rest of the vars */
  --backgroundColor: var(--grey-50);
  --textColor: var(--grey-900);
  --borderRadius: 0.25rem;
  --letterSpacing: 1px;
  --transition: 0.3s ease-in-out all;
  --max-width: 1120px;
  --fixed-width: 600px;

  /* box shadow*/
  --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

body {
  background: var(--backgroundColor);
  font-family: var(--moonaFont);
  font-weight: 400;
  line-height: 1.75;
  color: var(--textColor);
  padding-top: 100px;

  
}
/* Light mode */
@media (prefers-color-scheme: light) { 
	body { 
		background-color: var(--backgroundColor);
		color: var(--textColor);
	 }
	 .navbar { 
		   border-bottom: 1px solid lightgray;
		   background-color: var(--grey-50);
	 }
	 .single-ingredient {
		 border-bottom: 2px solid var(--grey-200);
		 padding-bottom: 0.75rem;
		 color: var(--grey-600);
	 }
}
/* Dark mode */
@media (prefers-color-scheme: dark) {
	body {
		 background-color: rgb(39, 39, 39);
		 color: white;
	 }
	 .navbar {
		 background-color: var(--black);
		 border-bottom: 1px solid var(--favicon_green);
	}
       	h3 {
		color: var(--green-dark);
	}
	.single-ingredient {
		border-bottom: 2px solid #6d6d6dc7;
	}
	.single-ingredient label {
		color: var(--white);
	}
	.single-ingredient input[type="checkbox"]:checked + label {
		color: var(--favicon_green);
	}
}

p {
  margin-top: 0;
  margin-bottom: 1.5rem;
  max-width: 40em;
}

h1 {
  font-family: var(--headingFont)
}
h2,
h3{
  font-family: var(--headingFont);
}
h4 {
  margin: 0;
  margin-bottom: 0.5rem;
  font-family: var(--moonaFont);
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: var(--letterSpacing);
  
  line-height: 1.3;
  height: 1.3;
  overflow: hidden;

}
h5 {
  margin: 0;
  margin-bottom: 1.38rem;
  font-family: var(--moonaFont);
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: var(--letterSpacing);
}

h1 {
  margin-top: 0;
  font-size: 3.3rem;
  color: var(--favicon_green);
}
@media screen and (max-width:500px) {
  h1{
     font-size: 3rem;
  }
}
h1:hover { text-decoration: none;}
h1:active { text-decoration: none; }
h1:link { text-decoration: none; }
h1:visited { text-decoration: none; }

h2 {
  font-size: 2.441rem;
}

h3 {
  font-size: 1.953rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.25rem;
}

small,
.text-small {
  font-size: var(--smallText);
}

a {
  text-decoration: none;
}
ul {
  list-style-type: none;
  padding: 0;
  vertical-align: bottom;
}
dl, ol, ul {
  margin-top: 0;
  margin-bottom: 0;
}
.img {
  width: 100%;
  display: block;
  object-fit: cover;
}
/* buttons */

.btn {
  cursor: pointer;
  color: var(--white);
  background: var(--green-dark);
  border: transparent;
  border-radius: var(--borderRadius);
  letter-spacing: var(--letterSpacing);
  padding: 0.375rem 0.75rem;
  box-shadow: var(--shadow-1);
  transition: var(--transtion);
  display: inline-block;
  vertical-align: top;
}
.btn:hover {
  background: var(--favicon_green);
  box-shadow: var(--shadow-3);
}
.btn-hipster {
  color: var(--favicon_green);
  background: var(--favicon_green);
}
.btn-hipster:hover {
  color: var(--favicon_green);
  background: var(--favicon_green);
}
.btn-block {
  width: 100%;
}

/* alerts */
.alert {
  padding: 0.375rem 0.75rem;
  margin-bottom: 1rem;
  border-color: transparent;
  border-radius: var(--borderRadius);
}

.alert-danger {
  color: var(--red-dark);
  background: var(--red-light);
}
.alert-success {
  color: var(--green-dark);
  background: var(--green-light);
}
/* form */

.form {
  width: 90vw;
  max-width: var(--fixed-width);
  background: #794821ad;
  border-radius: var(--borderRadius);
  box-shadow: var(--shadow-2);
  padding: 2rem 2.5rem;
  margin: 3rem auto;
}
.form-label {
  display: block;
  font-size: var(--smallText);
  margin-bottom: 0.5rem;
  letter-spacing: var(--letterSpacing);
  color: var(--white);
}
.form-input,
.form-textarea {
  width: 100%;
  padding: 0.375rem 0.75rem;
  border-radius: var(--borderRadius);
  background: var(--backgroundColor);
  border: 1px solid var(--grey-200);
}

.form-row {
  margin-bottom: 1rem;
}

.form-textarea {
  height: 7rem;
}
::placeholder {
  font-family: inherit;
  color: var(--grey-400);
}
.form-alert {
  color: var(--red-dark);
  letter-spacing: var(--letterSpacing);
}
/* alert */

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loading {
  width: 6rem;
  height: 6rem;
  border: 5px solid var(--grey-400);
  border-radius: 50%;
  border-top-color: var(--green-dark);
  animation: spinner 0.6s linear infinite;
}
.loading {
  margin: 0 auto;
}
/* title */

.title {
  text-align: center;
}

.title-underline {
  background: var(--green-dark);
  width: 7rem;
  height: 0.25rem;
  margin: 0 auto;
  margin-top: -1rem;
}

/*
=============== 
Navbar
===============
*/

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--moonaFont);
  /*border-bottom: 1px solid lightgray;*/
  position: fixed;
  top: 0;
  width: 100%;
  /*background-color: var(--grey-50);*/
  z-index: 10;

}

.nav-center {
  width: 90vw;
  max-width: var(--max-width);
  /*background: red*/
}

.nav-header {
  height: 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
}
.nav-header img{
  width: 200px;
}

.nav-btn {
  padding: 0.15rem 0.75rem;
  height: 2rem;
}
.nav-btn i{
  font-size: 1.25rem;
}

.nav-logo {
  display: flex;
  align-items: center;
  font-family: var(--headingFont);
}
.nav-logo:hover{text-decoration: none;}

ul {
  list-style-type: none;
  padding: 0;
}


.nav-links {
  height: 0;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  /* to-do */
  height: 0;
  overflow: hidden;
  transition: var(--transition);
}
.nav-links ul{
  flex-direction: row;
  font-family: var(--moonaFont);
  white-space: nowrap;
  display: flex;
}
.nav-links.show-links {
  height: 310px;
}
.nav-link {
  display: block;
  text-align: center;
  text-transform: capitalize;
  white-space: nowrap;
  color: var(--grey-900);
  letter-spacing: var(--letterSpacing);
  padding: 1rem 0;
  border-top: 1px solid var(--grey-500);
  transition: var(--transition);
}
a.nav-link {
  font-size: 1px;
}
.nav-link:hover {
  color: var(--favicon_green);
}

.contact-link a{
  padding: 0.15rem 1rem;
}
a.btn {
  font-family: var(--moonaFont);
  font-size: 18px;
}

@media screen and (min-width:992px) {
  .nav-btn{
    display: none;
  }
  .navbar{
    height: 6rem;
  }
  .nav-center{
    display: flex;
    align-items: center;
  }
  .nav-header{
    height: auto;
    margin-right: 2rem;
  }
  .nav-links{
    height: auto;
    flex-direction: row;
    align-items: center;
    width: 100vw;
  }
  .nav-link{
    padding: 0;
    border-top: none;
    margin-right: 1rem;
    font-size: 1rem;
  }
  .contact-link{
    margin-left: auto;
    margin-right: 0;
  }
}




/*
=============== 
Page
===============
*/
.page {
  /*background: red; */
  width: 90vw;
  max-width: var(--max-width);
  margin: 0 auto;
  padding-top: 0.5rem;
  min-height: calc(100vh - (6rem + 4rem));
}


/*
=============== 
Footer
===============
*/
.page-footer {
  height: 4rem;
  text-align: center;
  background-color: var(--black);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
}
.page-footer p{
  margin-bottom: 0;
  font-size: 11pt;
}
.footer-logo,
.page-footer a{
  color: var(--favicon_green);
}



/*
=============== 
Loading
===============
*/
.lds-spinner {
  color: official;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: var(--favicon_green);
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*
=============== 
Homepage
===============
*/
.hero {
  height: 40vh;
  /* Server background: */
  /* background: url('/root/goodfoodwithmoo_mern/client/src/styles/assets/stylized_gfwm.JPG') center/cover no-repeat; */
  /* Local background: */
  background: url('./assets/stylized_gfwm.JPG') center/cover no-repeat;
  margin-bottom: 2rem;
  border-radius: var(--borderRadius);
  position: relative;
}
.hero-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3); /*increase last number to make pic darker */
  border-radius: var(--borderRadius);
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1600px) {
  .hero {
    height: 50vh;
  }
}
@media screen and (max-width:550px) {
  .hero{
    height: 20vh;
  }
}
.hero-text {
  text-align: center;
  color: var(--white);
}
.hero h1_white {
  font-family: var(--headingFont);
  margin-top: 0;
  font-size: 3.052rem;
  color: var(--white)
}
.hero h4 {
  /* font-family: Arial, Helvetica, sans-serif; */
  color: var(--white);
  text-transform: capitalize;
}
.main-filler a{
  color: var(--favicon_green);
  text-decoration: underline;
}

/* .main-filler {
  background-color: var(--green-dark);
  padding-top: 1rem;
  padding-bottom: 1rem;
} */
.main-filler h5{
  /* color: var(--white); */
  margin-bottom: 0rem;
  text-align: center;
}



@media screen and (min-width:768px) {
  .hero-text h1_white{
    font-size: 4rem;
    margin-bottom: 0;
  }
}
/*
=============== 
Recipes
===============
*/
.recipes-container {
  display: grid;
  gap: 2rem;
  padding-top: 1rem;
  /* border: 2px solid red; */
}
.home-recipes-container {
 display: grid;
 gap: 2rem;
 padding-top: 1rem;
}
.home-tags-container {
  /* border: 2px solid blue; */
  /* order: 1; */
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
}
.recipes-filters{
  border-right: 1px solid lightgray;
}
.recipes-filters h3{
  font-family: var(--headingFont);
  font-weight: 550;
  font-size: 2.5rem;
}
.recipes-filters input[type="text"] {
  width: 175px;
}

.recipe-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem 1rem;
  padding-bottom: 1rem;
  align-items: end;
  /* border: 2px solid green; */
}

.recipes-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem 1rem;
  padding-bottom: 1rem;
  align-items: end;

  /* Bing chatbot recommendation*/
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem; */
}
.recipes-in-list {
  display: block;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem 1rem;
  padding-bottom: 0rem;
  align-items: end;
  transition: all 0.3s ease-in-out;
  /* background-color: #335537; */
  /* border: 2px solid red; */
}
.recipes-in-list:hover {
  background-color: var(--secondary-color);
  box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
}
.recipe-img:hover ~ h4 {
  color: var(--favicon_green);
}

.recipes-in-list h4 {
  margin: 0;
  margin-bottom: 0.5rem;
  /* font-size: 16px; */
  font-family: var(--headingFont);
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: var(--letterSpacing);
  line-height: 1.3;
  height: 1.3;
  overflow: hidden;
  color: var(--green-dark);
  padding-left: 5px;
}
.recipes-in-list a:hover{
  text-decoration: none;
}
.recipes-in-list a:hover h4 {
  color: var(--favicon_green);
}


.recipes-in-list p{
  color: var(--favicon_green);
  font-size: 15px;
  margin-bottom: 0.2rem;
}

.tags-section h3{
  padding-top: 1rem;
}
.tags-section input[type="checkbox"]:checked + label::after {
  background: #33885e;
}

.single-ingredient {
  display: flex;
  align-items: center;
  padding-top: 1rem;
}

.single-ingredient label {
  padding-left: 10px;
  margin-bottom: 0rem;
}

.single-ingredient input[type="checkbox"]:checked + label {
  text-decoration: line-through;
}
.recipe-content {
  display: flex;
}
@media (max-width: 600px) {
  .second-column {
    order: -1;
  }
}


.home-tags-container h4{
  margin-bottom: 0.5rem;
  font-weight: 500;
  text-transform: capitalize;
  color: var(--favicon_green);
  font-family: var(--headingFont);
  /*font-weight: bold;*/
  padding-left: 2px;
  font-size: 2rem;
}
.home-tags-list{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr ;
}
.home-tags-list a {
  text-transform: capitalize;
  display: block;
  color: var(--green-dark);
  transition: var(--transition);
  /*width: 8rem;*/
  font-size: 1.4rem;
  padding-left: 0.2rem;
  font-family: var(--moonaFont2);
  font-weight: bold;
  margin: 0.4rem;
  border-color: var(--green-dark);
  border-style: double;
}
.home-tags-list a:hover {
  color: var(--favicon_green);
  box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
  text-decoration: none;
}
.recipe {
  display: block;
}
.recipe-img{
  height: 15rem;
  border-radius: var(--borderRadius);
  margin-bottom: 0rem;
}
.recipe h5{
  margin-bottom: 0;
  line-height: 1;
  color: var(--brown-300);
}
.recipe p{
  margin-bottom: 0;
  line-height: 1;
  margin-top: 0.5rem;
  color: var(--favicon_green);
  letter-spacing: var(--letterSpacing);
}
@media screen and (min-width: 150px) {
  .recipes-list{
    grid-template-columns: 1fr;
  }
  .recipe-img {
    height: 10rem;
  }
}
@media screen and (max-width: 500px) {
  .home-tags-list{
    display: grid;
    grid-template-columns: 1fr;
  }
  .home-tags-list a{
    justify-content: center;
    text-align: center;
  }
  .home-tags-container{
    text-align: left;
  }
}
@media screen and (min-width: 576px) {
  .recipes-list{
    grid-template-columns: 1fr 1fr;
  }
  .recipe-img {
    height: 10rem;
  }
}
@media screen and (min-width: 992px) {
  .recipes-container {
    grid-template-columns: 200px 1fr;
  }
  .home-recipes-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .recipe p{
    font-size: 0.85rem;
  }
  .home-tags-container {
    order: 0;
    grid-column: 2 / 3;
  }
  .home-tags-list {
    grid-template-columns: 1fr;
  }
}
@media screen and (min-width: 1200px) {
  .recipe h5 {
    font-size: 1.15rem;
  }
  .recipes-list{
    grid-template-columns: 1fr 1fr 1fr;
  }
  .btn{
    grid-column: 2 / 3;
  }
}
/*
=============== 
Error
===============
*/
.error-page {
  text-align: center;
  padding-top: 5rem;
}
.error-page h1{
  font-size: 9rem;
}
/*
=============== 
About
===============
*/
.featured-title {
  text-align: center;
}
.about-page h2 {
  text-transform: none;
  font-weight: 300;
  font-family: var(--moonaFont)
}
.about-page {
  display: grid;
  gap: 2rem 4rem;
  padding-top: 5rem;
  padding-bottom: 3rem;
}
.about-img {
  border-radius: var(--borderRadius);
  height: 300px;
}

@media screen and (min-width:992px) {
  .about-page{
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 400px;
    align-items: center;
    padding-top: 5rem;
  }
  .about-img {
    height: 100%;
  }
}
@media screen and (max-width:1130px) {
  .about-page {
    padding-top: 8rem;
  }
}
@media screen and (max-width:992px) {
  .about-page {
    padding-top: 1rem;
  }
}


/*
=============== 
Contact Page
===============
*/
.contact-form {
  width: 100%;
  margin: 0;
}
.contact-container {
  display: grid;
  gap: 2rem 3rem;
  padding-bottom: 3rem;
}
@media screen and (min-width:992px){
  .contact-container{
    grid-template-columns: 1fr 450px;
    align-items: center;
  }
}
/*
=============== 
Tags Page
===============
*/
.tags-wrapper{
  display: grid;
  gap: 2rem;
  padding-bottom: 3rem;
}
.tag{
  background: var(--green-dark);
  border-radius: var(--borderRadius);
  text-align: center;
  color: var(--white);
  transition: var(--transition);
  padding: 0.75rem 0;
}
.tag:hover {
  background: darkgreen;
}
.tag h5, .tag p{
  margin-bottom: 0;
}
.tag h5 {
  font-weight: 300;
}
@media screen and (min-width:576px){
  .tags-wrapper{
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width:992px){
  .tags-wrapper{
    grid-template-columns: repeat(3, 1fr);
  }
}
/*
=============== 
Single Recipe Page
===============
*/
.recipe-hero{
  display: grid;
  gap: 3rem;
}
.recipe-hero-img{
  height: 300px;
  border-radius: var(--borderRadius);
}
.recipe-info p{
  color: var(--green-dark);
}
.recipe-icons{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin: 2rem 0;
  text-align: center;
}
.recipe-icons i{
  font-size: 1.5rem;
  color: var(--green-dark);
}
.recipe-icons h5,
.recipe-icons p{
  margin-bottom: 0;
  font-size: 0.85rem;
}
.recipe-icons p{
  color: var(--green-dark);
}
.recipe-tags{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 0.7rem;
  font-weight: 600;
}
.recipe-tags a{
  background: var(--green-dark);
  border-radius: var(--borderRadius);
  color: white;
  padding: 0.05rem 0.5rem;
  margin: 0 0.25rem;
  text-transform: capitalize;
}
.tags-section {
  padding-top: 1rem;
  font-family: var(--moonaFont2);
  font-weight: bold;
}
.tags-section label:hover {
  color: var(--favicon_green);
}
.tags-section label{
  padding-left: 0.5rem;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
   /*
     Introduced in Internet Explorer 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
   */
   -ms-user-select: none;
   user-select: none;
}

@media screen and (min-width:992px){
  .recipe-hero{
    grid-template-columns: 4fr 5fr;
    align-items: center;
  }
}
.recipe-content {
  padding: 3rem 0;
  display: grid;
  gap: 2rem 4rem;
}
@media screen and (min-width:992px){
  .recipe-content{
    grid-template-columns: 2fr 1fr;
    align-items: center;
  }
}
.single-instruction header {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1.5rem;
  align-items: center;
}
.single-instruction header p{
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 0;
  color: var(--green-dark);
}
.single-instruction header > p {
  color: var(--grey-400);
}
.single-instruction header div{
  height: 2px;
  background: var(--green-dark);
}
.second-column{
  display: grid;
  row-gap: 2rem;
  vertical-align: top;
}
.single-ingredient{
  /*border-bottom: 2px solid var(--grey-200);*/
  padding-bottom: 0.75rem;
  /*color: var(--grey-600);*/
}
.single-tool{
  border-bottom: 2px solid var(--grey300);
  padding-bottom: 0.75rem;
  color: #222;
  text-transform: capitalize;
}

/*
=============== 
Contact test page
===============
*/
.container{
  background-color: white;
  padding: 40px 0;
  margin: 100px auto;
  width: 90%;
  box-shadow: 0 0 20px #000000b3;
  border-radius: 8px;
  font-family: "montserrat",sans-serif;
}

.container h1{
  font-family: var(--headingFont);
  text-align: center;
  font-weight: 400;
  font-size: 3rem;
}
.container form{
  width: 100%;
  padding: 10px;

}

.container label{
  display: block;
  letter-spacing: 0.1rem;
  font-style: italic;
  margin-bottom: 3px;
  font-weight: 500;
}

.container input, 
.container textarea{
  width: 100%;
  margin:o auto;
  padding: 10px;
  border: none;
  border-radius: 8px;
  box-shadow: 1px 1px 4px 1px rgb(58,57,57);
}

.container input:focus, .container textarea:focus{
  box-shadow: 1px 1px 4px 1px #9b59b6;
}

.singleItem-name{
  margin: 10px 0;
}
.singleItem-email{
  margin: 10px 0;
}
.singleItem-message{
  margin: 10px 0;
}
.singleItem-message textarea{
  height: 9rem;
}



.msg{
  text-align: center;
  margin: 0 auto;
  margin-bottom: 10px;
  letter-spacing: 0.2rem;
  font-style: italic;
  width: 340px;
  padding: 3px;
  font-size: 16px;
  font-weight: bold;
  color: rgb(5,110,5);
  background-color: #fff;
  border-radius: 5px;
  display: none;
}

.msgAppear{
  display: block;
}


.slider-container {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.slider-container input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.slider-container input:checked + .slider {
  background-color: var(--favicon_green);
}

.slider-container input:checked + .slider:before {
  transform: translateX(26px);
}

.copy-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem; /* Adjust size */
  color: #333; /* Default icon color */
  transition: color 0.3s ease;
}

.copy-button:hover {
  color: var(--favicon_green); /* Highlight color on hover */
}

.copy-button:focus {
  outline: none;
}







